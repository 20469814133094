// Generated by Framer (2fed5cd)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["SuyyxM_Gh", "x4wEGMQwj"];

const serializationHash = "framer-a2evK"

const variantClassNames = {SuyyxM_Gh: "framer-v-1thh3di", x4wEGMQwj: "framer-v-1a7qbcv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "SuyyxM_Gh", "Variant 2": "x4wEGMQwj"}

const getProps = ({click, height, id, width, ...props}) => { return {...props, E1mVRsZ5W: click ?? props.E1mVRsZ5W, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "SuyyxM_Gh"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, E1mVRsZ5W, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "SuyyxM_Gh", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1rcz9pf = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (E1mVRsZ5W) {const res = await E1mVRsZ5W(...args);
if (res === false) return false;}
setVariant("x4wEGMQwj")
})

const onTap1k652a6 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (E1mVRsZ5W) {const res = await E1mVRsZ5W(...args);
if (res === false) return false;}
setVariant("SuyyxM_Gh")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1thh3di", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"SuyyxM_Gh"} onTap={onTap1rcz9pf} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({x4wEGMQwj: {"data-framer-name": "Variant 2", onTap: onTap1k652a6}}, baseVariant, gestureVariant)}><motion.div className={"framer-9tevkd"} layoutDependency={layoutDependency} layoutId={"SYsmZ4njc"} style={{backgroundColor: "var(--token-88b875e7-8ca4-4532-b8a8-281609a0f010, rgb(15, 30, 53))", borderBottomLeftRadius: 1, borderBottomRightRadius: 1, borderTopLeftRadius: 1, borderTopRightRadius: 1, rotate: 0}} variants={{x4wEGMQwj: {rotate: 90}}}/><motion.div className={"framer-6slq1w"} layoutDependency={layoutDependency} layoutId={"PmAsTfzHh"} style={{backgroundColor: "var(--token-88b875e7-8ca4-4532-b8a8-281609a0f010, rgb(15, 30, 53))", borderBottomLeftRadius: 1, borderBottomRightRadius: 1, borderTopLeftRadius: 1, borderTopRightRadius: 1, rotate: 90}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-a2evK.framer-nj84y0, .framer-a2evK .framer-nj84y0 { display: block; }", ".framer-a2evK.framer-1thh3di { cursor: pointer; height: 20px; overflow: hidden; position: relative; width: 20px; }", ".framer-a2evK .framer-9tevkd, .framer-a2evK .framer-6slq1w { bottom: 0px; flex: none; left: 9px; overflow: hidden; position: absolute; right: 9px; top: 0px; will-change: var(--framer-will-change-override, transform); }", ".framer-a2evK.framer-v-1a7qbcv.framer-1thh3di { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 20px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"x4wEGMQwj":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"E1mVRsZ5W":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerlQti8DpUN: React.ComponentType<Props> = withCSS(Component, css, "framer-a2evK") as typeof Component;
export default FramerlQti8DpUN;

FramerlQti8DpUN.displayName = "Navigation/FAQ icon";

FramerlQti8DpUN.defaultProps = {height: 20, width: 20};

addPropertyControls(FramerlQti8DpUN, {variant: {options: ["SuyyxM_Gh", "x4wEGMQwj"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, E1mVRsZ5W: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerlQti8DpUN, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})